.wallet-card {
  display: flex;
  align-items: center;
}

.wallet-progress-circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.wallet-progress-inner-circle {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.wallet-progress-text {
  font-weight: bold;
  color: black;
}

.wallet-info {
  margin-left: 16px;
}

.wallet-title {
  font-weight: bold;
  font-size: 15px;
}

.wallet-subtitle {
  color: black;
  font-weight: semi-bold;
}

.wallet-balance-bar {
  width: 100%;
  height: 8px;
  background-color: #e5e7eb;
  border-radius: 4px;
  overflow: hidden;
}

.wallet-balance {
  height: 100%;
}

.wallet-balance-text {
  margin-left: 8px;
  color: #6b7280;
}
