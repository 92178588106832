#readers {
  position: relative;
  border: 1px solid #000;
  overflow: hidden;
}
#reader {
  position: relative;
  overflow: hidden;
}

.scanner-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  z-index: 10;
  background: red;
  animation: scan 2s linear infinite; /* Infinite loop */
}

@keyframes scan {
  0% {
    top: 0;
  }

  90% {
    top: 100%;
  }

  100% {
    top: -2px; /* Reset position to start from the top again */
  }
}
