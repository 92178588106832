@media print {
  body {
    visibility: hidden;
    counter-reset: page;
  }

  #print-component,
  #print-component * {
    visibility: visible;
  }

  #print-component1,
  #print-component1 * {
    visibility: visible;
  }

  #print-header,
  #print-header * {
    visibility: visible;
    height: 20%;
  }

  #print-measurementSlip,
  #print-measurementSlip * {
    visibility: visible;
    height: 20%;
  }

  #print-thermal,
  #print-thermal * {
    visibility: visible;
    height: 20%;
  }

  #print-header1,
  #print-header1 * {
    visibility: visible;
    height: 20%;
    left: 15;
    top: 15;
  }

  #print-component {
    position: absolute;
    left: 15;
    top: 15;
  }

  #print-component1 {
    position: absolute;
    left: 0;
    top: 20;
  }

  @page {
    margin: 20px;
    counter-increment: page;
  }

  @page :after {
    content: 'Page ' counter(page);
    position: fixed;
    bottom: 10px;
    right: 10px;
    font-size: 10pt;
  }
}
