@media print {
  body {
    visibility: hidden;
  }

  #print-component,
  #print-component * {
    visibility: visible;
  }

  #print-component1,
  #print-component1 * {
    visibility: visible;
  }

  #print-header,
  #print-header * {
    visibility: visible;
    height: 20%;
  }

  #print-measurementSlip,
  #print-measurementSlip * {
    visibility: visible;
    height: 20%;
  }

  #print-thermal,
  #print-thermal * {
    visibility: visible;
    height: 20%;
  }

  #print-header1,
  #print-header1 * {
    visibility: visible;
    height: 20%;
  }

  #print-component {
    position: absolute;
    left: 0;
    top: 0;
    margin: 10;
  }

  #print-component1 {
    position: absolute;
    left: 0;
    top: 20;
  }
}

@page {
  size: landscape;
  margin-top: 0.5cm; /* Set an equal margin on the top and bottom of each page */
  margin-bottom: 1cm; /* Adjust this value to set the desired bottom margin */
}
