.diagonal-container {
  position: relative;
  /* width: 200px; 
    height: 200px;  */
  border: 1px dotted #d44363; /* Add dotted border */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.diagonal-1,
.diagonal-2 {
  position: absolute;
  width: 300px;
  height: 1px;
  background-color: transparent; /* Make the background transparent */
  border-bottom: 1px dotted #d44363; /* Add dotted line */
}

.diagonal-1 {
  transform: rotate(-45deg);
  top: 50%;
}

.diagonal-2 {
  transform: rotate(45deg);
  bottom: 50%;
}

.diagonal2-container {
  position: relative;
  /* width: 200px; 
      height: 200px;  */
  border: 1px dotted #d33356; /* Add dotted border */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.diagonal2-1,
.diagonal2-2 {
  position: absolute;
  width: 300px;
  height: 1px;
  background-color: transparent; /* Make the background transparent */
  border-bottom: 1px dotted #d33356; /* Add dotted line */
}

.diagonal2-1 {
  transform: rotate(-45deg);
  top: 50%;
}

.diagonal2-2 {
  transform: rotate(45deg);
  bottom: 50%;
}
