@media print {
  body {
    visibility: hidden;
  }

  #print-component,
  #print-component * {
    visibility: visible;
  }

  #print-component1,
  #print-component1 * {
    visibility: visible;
  }

  #print-header,
  #print-header * {
    visibility: visible;
    height: 20%;
  }

  #print-measurementSlip,
  #print-measurementSlip * {
    visibility: visible;
    height: 20%;
  }

  #print-thermal,
  #print-thermal * {
    visibility: visible;
    height: 20%;
  }

  #print-header1,
  #print-header1 * {
    visibility: visible;
    height: 20%;
  }

  #print-component {
    position: absolute;
    left: 0;
    top: 0;
  }

  #print-component1 {
    position: absolute;
    left: 0;
    top: 20;
  }
}

@page {
  size: portrait;
}
