.active {
  color: red;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  appearance: none;
}

.responsive-container {
  padding: 20px;
  background-color: #f0f0f0;
  text-align: center;
  font-family: 'Arial', sans-serif;
}

/* Media query for landscape orientation */
@media screen and (orientation: landscape) {
  .responsive-container {
    font-size: 24px; /* Adjust font size for landscape */
  }
}
