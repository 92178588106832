/* qrCss.css */

@media print {
  body {
    visibility: hidden;
  }

  #print-container {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
  }

  .print-page {
    visibility: visible;
    /* width: 138px; */
    height: 460px; /* Adjust height as needed */
    page-break-after: always; /* Forces each element to be on a new page */
  }

  .qr-slip {
    padding: 0 0px; /* Adjust padding as needed */
  }

  .print-page:last-child {
    page-break-after: auto; /* Prevents an extra blank page at the end */
  }
}
